import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone'
import { useState } from 'react'
import AlertDialogSlide from './AlertDialogSlide'

const ContactMe = () => {
  const [result, setResult] = useState('Send')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [open, setOpen] = useState({
    open: false,
    data: { header: '', text: '', textButton: '' },
  })

  const handleClickOpen = (data) => {
    setOpen(data)
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (name === '' || email === '' || message === '') {
      handleClickOpen({
        open: true,
        data: {
          header: 'Send notification',
          text: 'Message not sent, all fields must be filled!',
          textButton: 'Close',
        },
      })
      return
    }

    // https://muhimasri.com/blogs/mui-validation/
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(email)) {
      handleClickOpen({
        open: true,
        data: {
          header: 'Send notification',
          text: 'There is something wrong with your email address, please check it!',
          textButton: 'Close',
        },
      })
      return
    }

    setResult('Sending...')
    const formData = new FormData(event.target)
    formData.append('access_key', process.env.REACT_APP_ACCESS_KEY_HERE)
    formData.append('name', name)
    formData.append('email', email)
    formData.append('message', message)

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    })

    const data = await response.json()

    if (data.success) {
      // Success
      handleClickOpen({
        open: true,
        data: {
          header: 'Send notification',
          text: 'Your message has been successfully sent!',
          textButton: 'Close',
        },
      })
      setResult('Send')
      event.target.reset()
    } else {
      // Error
      console.log('Error', data)
      handleClickOpen({
        open: true,
        data: {
          header: 'Sending error',
          text: data,
          textButton: 'Close',
        },
      })
      setResult('Try again')
    }
  }

  /**
   * Успіх!
   * data: {name: 'Viktor', email: 'vyhivskyi.viktor@outlook.com', message: 'Привіт! Це тестовий лист!'}
    message: "Email sent successfully!"
    success: true
   */

  /**
   * Error
   * data: {files: Array(0), name: 'Viktor', email: 'vyhivskyi.viktor@outlook.com', message: 'Test text', access_key: 'undefined'}
    message: "Invalid Access Key! Please double check for extra space!"
    success: false
   */

  return (
    <Box
      component='form'
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      noValidate
      autoComplete='off'
    >
      <Stack spacing={2} width={{ xs: 300, sm: 360, md: 420 }}>
        <Typography variant='h3' sx={{ color: 'white', textAlign: 'center' }}>
          Contact me
        </Typography>
        <Box>
          <TextField
            id='yourName'
            name='name'
            type='text'
            fullWidth
            label='Your Name'
            variant='outlined'
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <Box>
          <TextField
            id='yourEmail'
            name='email'
            type='email'
            fullWidth
            label='Your email address'
            variant='outlined'
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box>
          <TextField
            id='yourText'
            name='message'
            fullWidth
            label='Your letter'
            variant='outlined'
            multiline
            maxRows={12}
            minRows={2}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            type='submit'
            size='large'
            endIcon={<SendTwoToneIcon />}
          >
            {result}
          </Button>
        </Box>
      </Stack>
      <AlertDialogSlide open={open} setOpen={setOpen} />
    </Box>
  )
}

export default ContactMe
