import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { useRef, useState } from 'react'
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material'
import './App.css'
import SlideHome from './components/SlideHome'
import SlideOther from './components/SlideOther'
import TrailsText from './components/TrailsText'
import Logo from './logo/myLogoNewDark.svg'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone'
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone'
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMailTwoTone'
import MyProjects from './components/MyProjects'
import ContactMe from './components/ContactMe'
import Footer from './components/Footer'
import { Helmet } from 'react-helmet-async'
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone'
import KeyboardDoubleArrowUpTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowUpTwoTone'

function App() {
  const parallax = useRef()
  const pages = 4
  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const menu = [
    {
      text: 'Home',
      icon: <CottageTwoToneIcon />,
      page: 0,
    },
    {
      text: 'Pet-projects',
      icon: <WorkTwoToneIcon />,
      page: 1,
    },
    {
      text: 'Contact',
      icon: <ContactMailTwoToneIcon />,
      page: 2,
    },
  ]

  const dataHelmet = {
    currentLang: `en`,
    title: `Vyhivskyi portfolio site`,
    description: `Viktor Vyhivskyi's portfolio site, which hosts the latest projects, test sites and other interesting information related to the development of web sites and web design, as well as a feedback form.`,
  }

  const DrawerList = (
    <Box
      sx={{
        width: 280,
        background: 'rgba(6,19,54,1)',
        height: '100%',
      }}
      role='presentation'
      onClick={toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding sx={{ justifyContent: 'flex-end' }}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <IconButton onClick={toggleDrawer(true)}>
              {open ? (
                <CloseTwoToneIcon fontSize='large' />
              ) : (
                <MenuTwoToneIcon fontSize='large' />
              )}
            </IconButton>
          </Toolbar>
        </ListItem>

        <Divider />

        {menu.map((item) => (
          <ListItem
            key={item.text}
            disablePadding
            onClick={() => parallax.current.scrollTo(item.page)}
          >
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <Divider /> */}
    </Box>
  )

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Helmet>
        <html lang={dataHelmet.currentLang} />
        <title>{`${dataHelmet.title}`}</title>
        <meta name='description' content={`${dataHelmet.description}`} />
      </Helmet>
      <Parallax
        ref={parallax}
        pages={pages}
        style={{
          background: 'rgba(6,19,54,1)',
        }}
        config={{ mass: 2 }}
      >
        {/* СЛАЙД 1 */}
        <ParallaxLayer offset={0} speed={1}>
          <SlideHome />
        </ParallaxLayer>
        {/* Слайд 1, текст по центру */}
        <ParallaxLayer
          offset={0}
          speed={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TrailsText />
        </ParallaxLayer>

        {/* AppBar */}
        {/* Цей шар перекриває інші активні елементи, тому потрібні прозорі події миши */}
        <ParallaxLayer
          offset={0}
          speed={1}
          style={{
            pointerEvents: 'none',
          }}
          sticky={{ start: 0, end: pages }}
        >
          {/* А тут потрібно активувати події миши */}
          <Box sx={{ pointerEvents: 'auto' }}>
            <AppBar
              sx={{
                bgcolor: 'transparent',
                backgroundImage: 'none',
                boxShadow: 'none',
              }}
            >
              <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box
                  component='img'
                  src={Logo}
                  alt='[ VYHIVSKYI ]'
                  sx={{
                    maxHeight: 24,
                    m: 4,
                  }}
                  onClick={() => parallax.current.scrollTo(0)}
                />
                <IconButton onClick={toggleDrawer(true)}>
                  {open ? (
                    <MenuOpenTwoToneIcon fontSize='large' />
                  ) : (
                    <MenuTwoToneIcon fontSize='large' />
                  )}
                </IconButton>
              </Toolbar>
            </AppBar>
          </Box>
        </ParallaxLayer>

        {/* IconButton */}
        <ParallaxLayer
          offset={0}
          speed={2}
          style={{
            pointerEvents: 'none',
            // border: '1px solid red',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <IconButton
            sx={{
              // border: '1px solid red',
              m: 3,
              fontSize: '3rem',
              pointerEvents: 'auto',
            }}
            onClick={() => parallax.current.scrollTo(1)}
          >
            <KeyboardArrowDownTwoToneIcon fontSize='inherit' />
          </IconButton>
        </ParallaxLayer>

        {/* Drawer */}
        {/* Цей шар перекриває інші активні елементи, тому потрібні прозорі події миши */}
        <ParallaxLayer
          speed={1}
          style={{
            pointerEvents: 'none',
          }}
          sticky={{ start: 0, end: pages }}
        >
          <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
        </ParallaxLayer>

        {/* СЛАЙД 2 */}
        <ParallaxLayer offset={1} speed={1}>
          <SlideOther />
        </ParallaxLayer>

        <ParallaxLayer offset={1.2} speed={2}>
          <MyProjects />
        </ParallaxLayer>

        {/* IconButton */}
        <ParallaxLayer
          offset={1}
          speed={3}
          style={{
            pointerEvents: 'none',
            // border: '1px solid red',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <IconButton
            sx={{
              // border: '1px solid red',
              m: 3,
              fontSize: '3rem',
              pointerEvents: 'auto',
            }}
            onClick={() => parallax.current.scrollTo(2)}
          >
            <KeyboardArrowDownTwoToneIcon fontSize='inherit' />
          </IconButton>
        </ParallaxLayer>

        {/* СЛАЙД 3 */}
        <ParallaxLayer offset={2} speed={1}>
          <SlideOther />
        </ParallaxLayer>

        <ParallaxLayer offset={2.2} speed={2}>
          <ContactMe />
        </ParallaxLayer>

        {/* IconButton */}
        <ParallaxLayer
          offset={2}
          speed={3}
          style={{
            pointerEvents: 'none',
            // border: '1px solid red',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <IconButton
            sx={{
              // border: '1px solid red',
              m: 3,
              fontSize: '3rem',
              pointerEvents: 'auto',
            }}
            onClick={() => parallax.current.scrollTo(3)}
          >
            <KeyboardArrowDownTwoToneIcon fontSize='inherit' />
          </IconButton>
        </ParallaxLayer>

        {/* СЛАЙД 4 */}
        <ParallaxLayer offset={3} speed={1} factor={0.72}>
          <SlideOther />
        </ParallaxLayer>

        <ParallaxLayer offset={3.2} speed={2} factor={1}>
          <Footer parallax={parallax} />
        </ParallaxLayer>

        {/* IconButton */}
        <ParallaxLayer
          offset={3}
          speed={3}
          style={{
            pointerEvents: 'none',
            // border: '1px solid red',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <IconButton
            sx={{
              // border: '1px solid red',
              m: 3,
              fontSize: '3rem',
              pointerEvents: 'auto',
            }}
            onClick={() => parallax.current.scrollTo(0)}
          >
            <KeyboardDoubleArrowUpTwoToneIcon fontSize='inherit' />
          </IconButton>
        </ParallaxLayer>
      </Parallax>
    </Box>
  )
}

export default App
