import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material'
import ArrowOutwardTwoToneIcon from '@mui/icons-material/ArrowOutwardTwoTone'
import Flickity from 'react-flickity-component'
import '../flickity.css'
import img01 from '../images/img1.png'
import img02 from '../images/img2.png'
import img03 from '../images/img3.png'
import img04 from '../images/img4.png'
import img05 from '../images/img5.png'

const flickityOptions = {
  // initialIndex: 1,
  imagesLoaded: true,
  percentPosition: false,
  freeScroll: true,
  groupCells: true,
  dragThreshold: 10,
  pageDots: true,
}

const MyProjects = () => {
  const dataCard = [
    {
      text: 'Project 01',
      description:
        'My first test project that I did on Bootstrap, the project is written using the React library.',
      textButton: 'Go to project',
      image: img01,
      link: 'https://pr1.vyhivskyi.com/',
    },
    {
      text: 'Project 02',
      description:
        'A project that implements authorization via Google and interesting animation using tsParticles, the project is written using the React library.',
      textButton: 'Go to project',
      image: img02,
      link: 'https://pr2.vyhivskyi.com/',
    },
    {
      text: 'Project 03',
      description:
        'The project in which authorization through Google is implemented, the project is written in php',
      textButton: 'Go to project',
      image: img03,
      link: 'https://pr3.vyhivskyi.com/',
    },
    {
      text: 'Project 04',
      description:
        'A project that displays information about countries using the REST Countries API. Written using the React library and Material UI.',
      textButton: 'Go to project',
      image: img04,
      link: 'https://pr4.vyhivskyi.com/',
    },
    {
      text: 'Project 05',
      description:
        'Weather information provided by the OpenWeather API, written in React using Redux, React Router.',
      textButton: 'Go to project',
      image: img05,
      link: 'https://weather.vyhivskyi.com/',
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // border: '1px solid red',
      }}
    >
      <Stack spacing={3} sx={{ width: '100%' }}>
        <Typography variant='h3' sx={{ color: 'white', textAlign: 'center' }}>
          Pet-projects
        </Typography>

        <Flickity options={flickityOptions}>
          {dataCard.map((card, i) => {
            return (
              <Box
                key={i}
                sx={{
                  m: 1,
                  // border: '1px solid red',
                }}
                className='carousel-cell'
              >
                <Card sx={{ maxWidth: 280 }} variant='outlined'>
                  <CardMedia sx={{ minHeight: '160px' }} image={card.image} />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {card.text}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {card.description}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      component='a'
                      href={card.link}
                      target='_blank'
                      rel='noreferrer'
                      variant='contained'
                      size='small'
                      endIcon={<ArrowOutwardTwoToneIcon />}
                    >
                      {card.textButton}
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            )
          })}
        </Flickity>
      </Stack>
    </Box>
  )
}

export default MyProjects
