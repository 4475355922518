// @ts-ignore
import React, { useState, useEffect } from 'react'
import { useTrail, animated } from '@react-spring/web'
// @ts-ignore
import styles from './TrailsText.module.css'
import { Box } from '@mui/material'

// @ts-ignore
// const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 100,
    height: open ? 110 : 0,
    from: { opacity: 0, x: 100, height: 0 },
  })
  return (
    <Box>
      {trail.map(({ height, ...style }, index) => (
        <animated.div key={index} className={styles.trailsText} style={style}>
          <animated.div style={{ height }}>{items[index]}</animated.div>
        </animated.div>
      ))}
    </Box>
  )
}

export default function App() {
  const [open, set] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      if (!open) set(true)
    }, 300)
  }, [open])

  return (
    <Box className={styles.container} onClick={() => set((state) => !state)}>
      <Trail open={open}>
        <span>Hello</span>
        <span>I'm</span>
        <span>a web</span>
        <span>dev</span>
      </Trail>
    </Box>
  )
}
