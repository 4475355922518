import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material'
import { forwardRef } from 'react'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const AlertDialogSlide = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen({
      open: false,
      data: { header: '', text: '', textButton: '' },
    })
  }

  return (
    <>
      <Dialog
        open={open.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <Box
          sx={{
            background: 'rgba(6,19,54,1)',
          }}
        >
          <DialogTitle>{open.data.header}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {open.data.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleClose}>
              {open.data.textButton}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default AlertDialogSlide
