import { Box, Typography } from '@mui/material'
import Logo from '../logo/myNewLogoIcon.svg'
// import CopyrightTwoToneIcon from '@mui/icons-material/CopyrightTwoTone'

const Footer = ({ parallax }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        // border: '1px solid red',
        height: '100vh',
        // marginTop: '-120px',
        color: 'white',
      }}
    >
      <Box
        component='img'
        src={Logo}
        alt='[ VYHIVSKYI ]'
        sx={{
          maxHeight: 84,
          mt: 4,
        }}
      />
      <Box
        onClick={() => parallax.current.scrollTo(0)}
        sx={{
          cursor: 'pointer',
          p: 3,
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography variant='h2'>Go up</Typography>
      </Box>
      <Box>
        <Box
          component='a'
          href='/privacy-policy.html'
          target='_blank'
          sx={[
            { color: 'white', mr: 1 },
            (theme) => ({
              '&:hover': {
                color: theme.palette.primary.light,
              },
            }),
          ]}
        >
          Privacy Policy
        </Box>
        <Box
          component='a'
          href='/cookies-policy.html'
          target='_blank'
          sx={[
            { color: 'white' },
            (theme) => ({
              '&:hover': {
                color: theme.palette.primary.light,
              },
            }),
          ]}
        >
          Cookies Policy
        </Box>
      </Box>
      {/* <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CopyrightTwoToneIcon />
        <Box
          component='img'
          src={Logo}
          alt='[ VYHIVSKYI ]'
          sx={{
            maxHeight: 24,
            my: 4,
            ml: 1,
          }}
        />
      </Box> */}
      <Box m={4}>
        <Typography variant='h5'>&copy; vyhivskyi.com</Typography>
      </Box>
    </Box>
  )
}

export default Footer
