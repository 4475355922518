import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { HelmetProvider } from 'react-helmet-async'

const root = ReactDOM.createRoot(document.getElementById('root'))
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#848a9a',
      main: '#38435e',
      dark: '#061336',
      contrastText: '#fff',
    },
  },
})

root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
